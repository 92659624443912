import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"

import Fold from "../components/fold"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const HomePage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Home" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
      <article className="post-content page-template no-image">
        <div className="trapezoid"></div>
        <Fold>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              width: "100%",
              margin: "0 auto",
              maxWidth: "1200px",
            }}
          >
            <div
              style={{
                padding: "6vw",
                flex: 1,
                maxHeight: "35vh",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                className="page-quote"
                style={{
                  maxWidth: "300px",
                }}
              >
                Focusing your life solely on making a buck shows a poverty of
                ambition. It asks too little of yourself.
                <br />– Barack Obama
              </div>
            </div>

            <div
              style={{
                flex: 1,
              }}
              className="post-content-body"
            >
              <h2>
                A small studio in Irvine, CA.
                <br />
                Parker and Leigh love creating.
              </h2>
            </div>
          </div>
        </Fold>
      </article>
      <div className="post-feed">
        <article
          className={`post-card with-image`}
          style={{
            backgroundImage: `url(${data.ourAppsHero.childImageSharp.fluid.src})`,
          }}
        >
          <Link to="/apps" className="post-card-link">
            <div className="post-card-content">
              <h2 className="post-card-title">Our Apps</h2>
            </div>
          </Link>
        </article>
        <article
          className={`post-card with-image`}
          style={{
            backgroundImage: `url(${data.developmentHero.childImageSharp.fluid.src})`,
          }}
        >
          <Link to="/development" className="post-card-link">
            <div className="post-card-content">
              <h2 className="post-card-title">Development</h2>
            </div>
          </Link>
        </article>
        {/* <article
          className={`post-card post-card-large with-image`}
          style={{
            backgroundImage: `url(${data.ourProductsHero.childImageSharp.fluid.src})`,
          }}
          >
          <Link to="/products" className="post-card-link">
            <div className="post-card-content">
          <h2 className="post-card-title">Maker</h2>
            </div>
          </Link>
        </article> */}
      </div>

      <div
        style={{
          overflow: "auto",
        }}
      >
        <h3 style={{ textAlign: "center" }}>Contact Us</h3>
        <p style={{ textAlign: "center" }}>
          <a href="mailto:hello@parkerandleigh.com">hello@parkerandleigh.com</a>
        </p>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    developmentHero: file(relativePath: { eq: "development-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ourAppsHero: file(relativePath: { eq: "our-apps-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    # ourProductsHero: file(relativePath: { eq: "our-products-hero.jpg" }) {
    #   childImageSharp {
    #     fluid(maxWidth: 1360) {
    #       ...GatsbyImageSharpFluid
    #     }
    #   }
    # }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <HomePage location={props.location} data={data} {...props} />
    )}
  />
)
